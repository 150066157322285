<div class="shopBG">
  <div class="navigation">
    <button mat-button class="grabbtn" routerLink="/store"></button>
    <button mat-button class="stagebtn" routerLink="/stream"></button>
    <button
      mat-button
      class="shopmorebtn"
      onClick="window.open('https://placeborneo.com/shop/')"
    ></button>
    <button mat-button class="mapbtn" routerLink="/"></button>
  </div>

  <button
    mat-button
    class="shopeeBIG"
    onClick="window.open('https://shopee.com.my/m/rwmf-shopee-live')"
  ></button>
</div>
