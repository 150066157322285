<div id="wrapper" style="max-width: 100%">
  <div style="max-width: 100%">
    <img
      class="backgroundimg"
      id="backgroundimg"
      src="../../assets/bg/MapBG.png"
      style="max-width: 100%; z-index: -1; position: absolute"
    />
  </div>

  <div class="maparea">
    <img
      class="scvmap"
      id="scvmap"
      src="../../assets/bg/Map.png"
      usemap="#map"
      border="0"
      width="100%"
    />
    <div class="legendsarea">
      <br /><br />
      <h3>RWMF 2021 Venue Legends:</h3>
      <p>1. Main Stage : Performances/Chats</p>
      <p>2. Bidayuh Longhouse : Workshop 1</p>
      <p>3. Iban Longhouse : Workshop 2</p>
      <p>4. Chinese Farm House : Workshop 3</p>
      <p>5. Malay House : Workshop 4</p>
      <p>6. Orang Ulu Longhouse : Food Hall</p>
      <p>7. Melanau Tallhouse : Gift Shop</p>
      <p>8. Penan Hut: Past Day Performances</p>
    </div>
    <div class="buttonarea">
      <a routerLink="/workshop"
        ><img
          class="buttonvid"
          src="../../assets/RWMF_Buttons/VidTourBtn.png"
          style="max-width: 100%"
      /></a>
      <a
        href="https://api2.enscape3d.com/v1/view/4718ac16-3057-48d3-9095-9b4d8b50ed69"
        target="_blank"
        ><img
          class="buttonvid"
          src="../../assets/RWMF_Buttons/walkthrough-button.png"
          style="max-width: 100%"
      /></a>
      <p>
        It is time for a Rainforest reunion! But this time our complete festival
        experience is virtual!
      </p>
      <p>
        We are welcoming you back to the Sarawak Cultural Village, the site of
        the legendary Rainforest World Music Festival.
      </p>
    </div>

    <map id="map_ID" name="map">
      <area
        id="mapsect"
        style="cursor: pointer"
        shape="poly"
        coords="77,15,84,19,79,26,71,24"
        alt="Bidayuh Longhouse"
        title="Bidayuh Longhouse"
        (click)="toggleDisplayBidayuhbtn()"
      />
      <div
        class="discovermorebidayuh"
        [hidden]="isShowBidayuhbtn"
        (click)="toggleDisplayBidayuhbtn()"
      >
        <img
          class="discovermorebtnbidayuh"
          (click)="toggleDisplayBidayuh()"
          src="../../assets/RWMF_Buttons/Discover.png"
        />
        <a routerLink="/workshop/work1"
          ><img
            class="discovermorebtnbidayuh"
            src="../../assets/RWMF_Buttons/workshop.png"
        /></a>
      </div>
      <area
        id="mapsect"
        style="cursor: pointer"
        shape="poly"
        coords="60,11,60,22,71,22,71,11"
        alt="Iban Longhouse"
        title="Iban Longhouse"
        (click)="toggleDisplayIbanbtn()"
      />
      <div
        class="discovermoreiban"
        [hidden]="isShowIbanbtn"
        (click)="toggleDisplayIbanbtn()"
      >
        <img
          class="discovermorebtniban"
          (click)="toggleDisplayIban()"
          src="../../assets/RWMF_Buttons/Discover.png"
        />
        <a routerLink="/workshop/work2"
          ><img
            class="discovermorebtniban"
            src="../../assets/RWMF_Buttons/workshop.png"
        /></a>
      </div>
      <area
        id="mapsect"
        style="cursor: pointer"
        shape="poly"
        coords="40,42,4,51,53,51,53,42"
        alt="Chinese Farm House"
        title="Chinese Farm House"
        (click)="toggleDisplayChinesebtn()"
      />
      <div
        class="discovermorechinese"
        [hidden]="isShowChinesebtn"
        (click)="toggleDisplayChinesebtn()"
      >
        <img
          class="discovermorebtnchinese"
          (click)="toggleDisplayChinese()"
          src="../../assets/RWMF_Buttons/Discover.png"
        />
        <a routerLink="/workshop/work3"
          ><img
            class="discovermorebtnchinese"
            src="../../assets/RWMF_Buttons/workshop.png"
        /></a>
      </div>
      <area
        id="mapsect"
        style="cursor: pointer"
        shape="poly"
        coords="33,32,33,40,45,40,45,32"
        alt="Malay House"
        title="Malay House"
        (click)="toggleDisplayMalaybtn()"
      />
      <div
        class="discovermoremalay"
        [hidden]="isShowMalaybtn"
        (click)="toggleDisplayMalaybtn()"
      >
        <img
          class="discovermorebtnmalay"
          (click)="toggleDisplayMalay()"
          src="../../assets//RWMF_Buttons/Discover.png"
        />
        <a routerLink="/workshop/work4"
          ><img
            class="discovermorebtnmalay"
            src="../../assets/RWMF_Buttons/workshop.png"
        /></a>
      </div>
      <area
        id="mapsect"
        style="cursor: pointer"
        shape="poly"
        coords="36,8,36,16,50,16,50,8"
        alt="Orang Ulu Longhouse"
        title="Orang Ulu Longhouse"
        (click)="toggleDisplayOrangUlubtn()"
      />
      <div
        class="discovermoreorangulu"
        [hidden]="isShowOrangUlubtn"
        (click)="toggleDisplayOrangUlubtn()"
      >
        <img
          class="discovermorebtnorangulu"
          (click)="toggleDisplayOrangUlu()"
          src="../../assets/RWMF_Buttons/Discover.png"
        />
        <a routerLink="/store"
          ><img
            class="discovermorebtnorangulu"
            src="../../assets/RWMF_Buttons/GrabFoodBtn.png"
        /></a>
      </div>
      <area
        id="mapsect"
        style="cursor: pointer"
        shape="poly"
        coords="36,24,36,32,46,32,46,24"
        alt="Melanau Tallhouse"
        title="Melanau Tallhouse"
        (click)="toggleDisplayMelanaubtn()"
      />
      <div
        class="discovermoremelanau"
        [hidden]="isShowMelanaubtn"
        (click)="toggleDisplayMelanaubtn()"
      >
        <img
          class="discovermorebtnmelanau"
          (click)="toggleDisplayMelanau()"
          src="../../assets/RWMF_Buttons/Discover.png"
        />
        <a routerLink="/shop"
          ><img
            class="discovermorebtnmelanau"
            src="../../assets/RWMF_Buttons/ShopeeBtn.png"
        /></a>
      </div>
      <area
        id="mapsect"
        style="cursor: pointer"
        shape="poly"
        coords="48,5,48,15,58,15,58,5"
        alt="Penan Hut"
        title="Penan Hut"
        (click)="toggleDisplayPenanbtn()"
      />
      <div
        class="discovermorepenan"
        [hidden]="isShowPenanbtn"
        (click)="toggleDisplayPenanbtn()"
      >
        <img
          class="discovermorebtnpenan"
          (click)="toggleDisplayPenan()"
          src="../../assets/RWMF_Buttons/Discover.png"
        />
        <a routerLink="/workshop/work5"
          ><img
            class="discovermorebtnpenan"
            src="../../assets/RWMF_Buttons/workshop.png"
        /></a>
      </div>

      <area
        id="mapsect"
        style="cursor: pointer"
        shape="poly"
        coords="80,7,80,17,70,17,70,7"
        alt="Live Stage"
        title="Live Stage"
        (click)="toggleDisplayLivebtn()"
      />
      <div
        class="livestage"
        [hidden]="isShowLivebtn"
        (click)="toggleDisplayLivebtn()"
      >
        <a routerLink="/stream"
          ><img
            class="livestagebtn"
            src="../../assets/RWMF_Buttons/WatchBtn.png"
        /></a>
      </div>
    </map>
  </div>

  <div class="infoarea" id="infoarea">
    <div class="bidayuhdiv" id="bidayuhdiv">
      <img
        class="bidayuhhouse"
        id="bidayuhhouse"
        src="../../assets/descrip/bidayuh.png"
        [hidden]="isShowBidayuh"
        (click)="toggleDisplayBidayuh()"
      />
    </div>

    <div class="ibandiv" id="ibandiv">
      <img
        class="ibanhouse"
        id="ibanhouse"
        src="../../assets/descrip/iban.png"
        [hidden]="isShowIban"
        (click)="toggleDisplayIban()"
      />
    </div>

    <div class="chinesediv" id="chinesediv">
      <img
        class="chinesehouse"
        id="chinesehouse"
        src="../../assets/descrip/chinese.png"
        [hidden]="isShowChinese"
        (click)="toggleDisplayChinese()"
      />
    </div>

    <div class="malaydiv" id="malaydiv">
      <img
        class="malayhouse"
        id="malayhouse"
        src="../../assets/descrip/malay.png"
        [hidden]="isShowMalay"
        (click)="toggleDisplayMalay()"
      />
    </div>

    <div class="oranguludiv" id="oranguludiv">
      <img
        class="oranguluhouse"
        id="oranguluhouse"
        src="../../assets/descrip/orangulu.png"
        [hidden]="isShowOrangUlu"
        (click)="toggleDisplayOrangUlu()"
      />
    </div>

    <div class="melanaudiv" id="melanaudiv">
      <img
        class="melanauhouse"
        id="melanauhouse"
        src="../../assets/descrip/melanau.png"
        [hidden]="isShowMelanau"
        (click)="toggleDisplayMelanau()"
      />
    </div>

    <div class="penandiv" id="penandiv">
      <img
        class="penanhouse"
        id="penanhouse"
        src="../../assets/descrip/penan.png"
        [hidden]="isShowPenan"
        (click)="toggleDisplayPenan()"
      />
    </div>
  </div>
</div>

<div id="warningmessage">
  <h2>For mobile view, flip your phone to landscape mode</h2>
</div>
