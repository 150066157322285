<mat-card class="info">
  <mat-card-header>
    <mat-card-title>Rainforest World Music Festival</mat-card-title>
    <mat-card-subtitle>Enjoy the virtual experience</mat-card-subtitle>
  </mat-card-header>
  <img
    mat-card-image
    src="../../assets/logo/RWMFW.png"
    alt="Logo of Rainforest World Music Festival"
    class="info-img"
  />
  <mat-card-content>
    <h3>
      It’s time for a Rainforest reunion! But this time our complete festival
      experience is virtual! We are welcoming you back to the Sarawak Cultural
      Village, the site of the legendary Rainforest World Music Festival, for
      three nights of programming, enjoyed through our innovative online
      platform. Join us for original performances from some of Sarawak’s best
      indigenous music stars, flashbacks to years gone by, snippets of backstage
      gossip and the story of the Rainforest Journey, told by its key players.
      Our fun, fast-paced shows will take you back to Sarawak to see what you
      have been missing and offer you the chance to win plenty of amazing
      prizes, from exclusive merchandise to festival tickets for 2022. If you’ve
      been missing Rainforest, don’t miss our virtual Rainforest reunion, coming
      to you on soon on 18-20 June.
    </h3>
  </mat-card-content>
</mat-card>
