<mat-sidenav-container class="sidenav-container">
  <mat-sidenav
    #drawer
    class="sidenav"
    fixedInViewport
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="false"
  >
    <mat-toolbar>Menu</mat-toolbar>
    <mat-nav-list>
      <a mat-list-item routerLink="/" (click)="drawer.close()">Home</a>
      <a
        mat-list-item
        href="https://virtualrwmf2021.xpoexpo.com/logout.php"
        (click)="drawer.close()"
        >Logout</a
      >

      <a mat-list-item routerLink="/stream" (click)="drawer.close()"
        >Main Stage</a
      >
      <a mat-list-item routerLink="/store" (click)="drawer.close()"
        >Food Hall</a
      >
      <a mat-list-item routerLink="/shop" (click)="drawer.close()">Gift Shop</a>
      <a mat-list-item routerLink="/workshop" (click)="drawer.close()"
        >Walkthrough</a
      >
    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav-content>
    <mat-toolbar>
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        *ngIf="isHandset$ | async"
      >
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <span class="logo" routerLink="/">
        <img class="avatar" src="assets/logo/RWMFW.png" />
        Rainforest World Music Festival
      </span>
      <span class="fill-space"></span>
      <div *ngIf="!(isHandset$ | async)">
        <a mat-button routerLink="/stream">Main Stage</a>
        <a mat-button routerLink="/workshop">Walkthrough</a>
        <a mat-button routerLink="/store">Food Hall</a>
        <a mat-button routerLink="/shop">Gift Shop</a>
        <a mat-button href="https://virtualrwmf2021.xpoexpo.com/logout.php"
          >Logout
        </a>
      </div>
      <!-- DROPDOWN MENU -->

      <button
        mat-icon-button
        [matMenuTriggerFor]="menu"
        aria-label="Example icon-button with a menu"
      >
        <mat-icon>more_vert</mat-icon>
      </button>

      <mat-menu #menu="matMenu">
        <a mat-menu-item routerLink="/about">
          <i>💬</i>
          <span>About</span>
        </a>
        <a
          mat-menu-item
          href="https://www.youtube.com/channel/UCaU5GxstD1A4uhB9r85HGzQ"
          target="_blank"
        >
          <i>📺</i>
          <span>Sarawak Tourism Youtube</span>
        </a>
      </mat-menu>
    </mat-toolbar>

    <ng-content></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>
