import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent implements OnInit {
  isShowBidayuh = true;
  isShowIban = true;
  isShowChinese = true;
  isShowMalay = true;
  isShowOrangUlu = true;
  isShowMelanau = true;
  isShowPenan = true;

  isShowBidayuhbtn = true;
  isShowIbanbtn = true;
  isShowChinesebtn = true;
  isShowMalaybtn = true;
  isShowOrangUlubtn = true;
  isShowMelanaubtn = true;
  isShowPenanbtn = true;
  isShowLivebtn = true;

  toggleDisplayBidayuhbtn() {
    this.isShowBidayuhbtn = !this.isShowBidayuhbtn;
    this.isShowIbanbtn = true;
    this.isShowChinesebtn = true;
    this.isShowMalaybtn = true;
    this.isShowOrangUlubtn = true;
    this.isShowMelanaubtn = true;
    this.isShowPenanbtn = true;
    this.isShowLivebtn = true;
  }

  toggleDisplayIbanbtn() {
    this.isShowIbanbtn = !this.isShowIbanbtn;
    this.isShowBidayuhbtn = true;
    this.isShowChinesebtn = true;
    this.isShowMalaybtn = true;
    this.isShowOrangUlubtn = true;
    this.isShowMelanaubtn = true;
    this.isShowPenanbtn = true;
    this.isShowLivebtn = true;
  }

  toggleDisplayChinesebtn() {
    this.isShowChinesebtn = !this.isShowChinesebtn;
    this.isShowBidayuhbtn = true;
    this.isShowIbanbtn = true;
    this.isShowMalaybtn = true;
    this.isShowOrangUlubtn = true;
    this.isShowMelanaubtn = true;
    this.isShowPenanbtn = true;
    this.isShowLivebtn = true;
  }

  toggleDisplayMalaybtn() {
    this.isShowMalaybtn = !this.isShowMalaybtn;
    this.isShowBidayuhbtn = true;
    this.isShowIbanbtn = true;
    this.isShowChinesebtn = true;
    this.isShowOrangUlubtn = true;
    this.isShowMelanaubtn = true;
    this.isShowPenanbtn = true;
    this.isShowLivebtn = true;
  }

  toggleDisplayOrangUlubtn() {
    this.isShowOrangUlubtn = !this.isShowOrangUlubtn;
    this.isShowBidayuhbtn = true;
    this.isShowIbanbtn = true;
    this.isShowChinesebtn = true;
    this.isShowMalaybtn = true;
    this.isShowMelanaubtn = true;
    this.isShowPenanbtn = true;
    this.isShowLivebtn = true;
  }

  toggleDisplayMelanaubtn() {
    this.isShowMelanaubtn = !this.isShowMelanaubtn;
    this.isShowBidayuhbtn = true;
    this.isShowIbanbtn = true;
    this.isShowChinesebtn = true;
    this.isShowMalaybtn = true;
    this.isShowOrangUlubtn = true;
    this.isShowPenanbtn = true;
    this.isShowLivebtn = true;
  }

  toggleDisplayPenanbtn() {
    this.isShowPenanbtn = !this.isShowPenanbtn;
    this.isShowBidayuhbtn = true;
    this.isShowIbanbtn = true;
    this.isShowChinesebtn = true;
    this.isShowMalaybtn = true;
    this.isShowOrangUlubtn = true;
    this.isShowMelanaubtn = true;
    this.isShowLivebtn = true;
  }

  toggleDisplayLivebtn() {
    this.isShowLivebtn = !this.isShowLivebtn;
    this.isShowBidayuhbtn = true;
    this.isShowIbanbtn = true;
    this.isShowChinesebtn = true;
    this.isShowMalaybtn = true;
    this.isShowOrangUlubtn = true;
    this.isShowMelanaubtn = true;
    this.isShowPenanbtn = true;
  }

  toggleDisplayBidayuh() {
    this.isShowBidayuh = !this.isShowBidayuh;
  }

  toggleDisplayIban() {
    this.isShowIban = !this.isShowIban;
  }

  toggleDisplayChinese() {
    this.isShowChinese = !this.isShowChinese;
  }

  toggleDisplayMalay() {
    this.isShowMalay = !this.isShowMalay;
  }

  toggleDisplayOrangUlu() {
    this.isShowOrangUlu = !this.isShowOrangUlu;
  }

  toggleDisplayMelanau() {
    this.isShowMelanau = !this.isShowMelanau;
  }

  toggleDisplayPenan() {
    this.isShowPenan = !this.isShowPenan;
  }

  constructor() {}

  ngOnInit() {
    var ImageMap = function (map, img) {
        var n,
          areas = map.getElementsByTagName('area'),
          len = areas.length,
          coords = [],
          previousWidth = 128;
        for (n = 0; n < len; n++) {
          coords[n] = areas[n].coords.split(',');
        }
        this.resize = function () {
          var n,
            m,
            clen,
            x = img.offsetWidth / previousWidth;
          for (n = 0; n < len; n++) {
            clen = coords[n].length;
            for (m = 0; m < clen; m++) {
              coords[n][m] *= x;
            }
            areas[n].coords = coords[n].join(',');
          }
          previousWidth = img.offsetWidth;
          return true;
        };
        window.onresize = this.resize;
      },
      imageMap = new ImageMap(
        document.getElementById('map_ID'),
        document.getElementById('scvmap')
      );
    imageMap.resize();
    return;
  }
}
